import React from "react";
import { Form, Navigate } from "react-router-dom";
import { isLogin } from "./helper";

const PrivateRoute =({component:Component, ...rest})=>{

    if(isLogin()){
        return <Component {...rest}/>;
    }
    return <Navigate to="/"/>;
};
export default PrivateRoute;
