import './App.css';
import React, { useEffect } from "react";

//import Login from './pages/LoginPage';

import { Welcome } from './pages/Welcome';


import { DetailsPage } from './pages/DetailsPage';

import { LoginPage } from './pages/LoginPage'

import { MerchantPage } from './pages/MerchantPage'

import PrivateRoute from './RouteGuards/PrivateRoute';


import { HashRouter as Router,Routes, Route, Navigate } from "react-router-dom";
import PassWordChange from './pages/ChangePassword';

function sessionTimeout() {
 
  let timeoutId;
  const TIMEOUT = 3600000; // Timeout period in milliseconds (60 minutes)

  const resetTimeout = () => {
   
    clearTimeout(timeoutId);
    timeoutId = setTimeout(logout, TIMEOUT);
  };

  const logout = () => {
    // Perform logout actions, such as clearing the session data
   
    window.location.href = "/"; // Redirect to the login screen
  };

  // Attach event listeners to reset the timeout when user interacts with the page
  ["mousedown", "mousemove", "keypress", "scroll", "touchstart"].forEach((eventType) =>
    document.addEventListener(eventType, resetTimeout, false)
  );

  resetTimeout();
}
function App() {
  useEffect(() => {
    sessionTimeout();
  }, []);
  return (

    <div className="container-fluid p-0">

       <div className="App">

       <Router>

       <Routes>
       <Route path="/*" element={<Navigate to="/" />}>
       </Route>
       <Route path="/" element={<LoginPage/>} exact>
        </Route>
        <Route path="/Add-items" element={<PrivateRoute component={Welcome}/>} exact>
        </Route>
        <Route path="/Change-Password" element={<PrivateRoute component={PassWordChange}/>} exact>
        </Route>
        <Route path="/Item-details" element={<PrivateRoute component={DetailsPage}/>} exact>
        </Route>
        <Route path="/merchantpage" element={<PrivateRoute component={MerchantPage}/>} exact>
        </Route>
        </Routes>

       </Router>

    </div>

    </div>

  );

}



export default App;