import React from 'react';
import RateChart from './RateChart';
import Form from 'react-bootstrap/Form';

const LoanSummaryDetails = (props) => { 
    const RBPObject = props.RBPObject;

    return (
        <div className="d-flex flex-row col-md-10 loan-summary">
            <div className="col-md-6">
            <div className="page-title">Loan Summary Details</div>
            <br />
            <div className="payment-checks">
                <div className="column">
                    <div className="col-md-10">
                        <div
                        className="d-flex flex-column payment-tile px-4 py-3"
                        style={{ background: "#FFF" ,fontWeight: "600", }}
                        >
                        <div className="amount" id="totalamount">
                            <span>{props.formatter.format(props.totalAmount)} </span>
                        </div>
                        <div className="description">
                            <span>Total price</span>
                        </div>
                        </div>
                    </div>

                    <div className="col-md-10 down-payment">
                        <div
                            className="d-flex flex-column payment-tile px-4 py-3"
                            style={{ background: "#FFF",fontWeight: "600" }}
                        >
                        <div className="amount d-flex flex-row justify-content-between">
                            <span>{props.formatter.format(props.downPayment)}</span>
                        </div>
                        <div className="description">
                            <span>Actual Down payment</span>
                            { props.addOnRateFlag && props.downPayment < props.addOnSelectedDp && false ?
                            <span style={{ color:"red" }}><br></br> Please input DP as per the segment required</span> : ""}
                        </div>
                        <div className="cta-wrap mt-2">
                            <button className="btn-purple btn" onClick={(e) => props.handleShow(e, 'screen')}>Change amount</button>
                            {props.showCompare && (<button className="btn-purple btn" onClick={() => props.setShowCompareModal(true)}>Compare</button>)}
                        </div>
                        </div>
                    </div>
                    {props.showCompare && props.showSilZero ?
                    <div className="col-md-10 sil-zero-switch">
                        <div className="d-flex px-4 py-3 justify-content-between align-items-center">
                            <div className="content">
                                <p className='mb-0'>SIL Zeros %</p>
                            </div>
                            <div className="sil-switch">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    props.setIsSilZero(e.target.checked)
                                }}
                                checked={props.isSilZero}
                                // label="Check this switch"
                            />
                            </div>
                        </div>
                    </div> : "" }
                    { window.innerWidth <= 768 && props.downPayment !== 0 && RBPObject.status === "Y" && <RateChart downPayment = {props.downPayment} RBPObject = {props.RBPObject} eligibleSegments = {props.eligibleSegments} formatter = {props.formatter} totalAmount = {props.totalAmount} /> }
                    <div className="col-md-10">
                        <div
                        className="d-flex flex-column payment-tile px-4 py-3"
                        style={{ background: "#FFF",fontWeight: "600" }}
                        >
                        <div className="amount">
                            <span>{props.formatter.format(props.loanableAmount)}</span>
                        </div>
                        <div className="description">
                            <span>Loanable amount</span>
                        </div>
                        </div>
                    </div>

                    <div className="col-md-10"
                        style={{
                        display:
                            RBPObject.status === "Y" && props.downPayment !== 0 && props.addOnRateFlag
                            ? ""
                            : "none",
                        }}
                    >
                        <div
                        className="d-flex flex-column payment-tile px-4 py-3"
                        style={{ background: "#FFF" ,fontWeight: "600"}}
                        >
                        <div className="amount">
                            <span>
                            {props.currentSegmentObj
                                ? props.currentSegmentObj?.addOnRate?.toFixed(2)
                                : ""}
                            %
                            </span>
                        </div>
                        <div className="description">
                            <span>Add-on rate</span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            { window.innerWidth > 767 && props.downPayment !== 0 && RBPObject.status === "Y" && <RateChart downPayment = {props.downPayment} RBPObject = {props.RBPObject} eligibleSegments = {props.eligibleSegments} formatter = {props.formatter} totalAmount = {props.totalAmount} /> }
        </div>
    );
};
  
export default LoanSummaryDetails;