import React from "react";
import { SpinnerDotted } from 'spinners-react';
import { useEffect, useState } from "react";

export const Loader = () => { 
    return (
          <div className="Spinner">
            <div className="Spinner-body">
            <SpinnerDotted enabled={true}/>
            </div>
          </div>
);
}
export default Loader;