import React from 'react';
import Modal from "react-bootstrap/Modal";
import CompareSlot from './CompareSlot'

export const CompareScreen = (props) => { 
    return (
        <Modal
            show={props.showCompareModal}
            onHide={props.handleCompareModalClose}
            animation={false}
            closeTimeoutMS={500}
            aria-labelledby="contained-modal-title-vcenter"
            className="compare-modal"
            backdrop="static"
        >
            <Modal.Body>
                <div class="head">
                    <span className="fa fa-arrow-left close-compare-modal" onClick={props.handleCompareModalClose}>&nbsp;</span>
                    <h2>Options comparison table</h2>
                    <h4>Proposed Loan Details</h4>
                </div>
                <div class="comparision-table">
                    <div class="flex-wrap">
                        
                        <CompareSlot {...props} slot={1} />
                        <CompareSlot {...props} slot={2} />
                        
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
  
export default CompareScreen;