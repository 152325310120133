import React from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CurrencyInput from "react-currency-input-field";
export const DownPayment = (logic) => {
    return (
        <Modal
            show={logic.showDownPay}
            onHide={logic.handleDownPayClose}
            animation={false}
            closeTimeoutMS={500}
            className="downpayment-modal"
            backdropClassName="downpayment-bd"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    How much is the down
                    <br />
                    payment?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CurrencyInput
                    intlConfig={{ locale: 'en-PH', currency: 'PHP' }}
                    id="down-pay-amount"
                    maxLength={6}
                    className="mb-3 form-control p-2"
                    prefix="₱"
                    name="down-pay-amount"
                    placeholder={logic.formatter.format(logic.downPayment)}
                    onChange={(e) => logic.handledownpayChange(e)}
                    onKeyDown={(e) => logic.handleDecimalNumbers(e)}
                    onInput={(e) => logic.handlePasteDecimalNumbers(e)}
                />
                {logic.RBPObject.status === "Y" &&
                    (<div
                        style={{
                            gap: "10px",
                        }}
                    >
                        <p>The bigger DP, the lower the add-on rate</p>
                    </div>)
                }

                <span>&nbsp;</span>
                <div className="d-grid gap-2">
                    <Button
                        style={{
                            backgroundColor: "rgba(120, 90, 255, 1)",
                            color: logic.disableApplynow ? "black" : "white",
                        }}
                        id="apply-now"
                        className="btn btn-primary p-2"
                        disabled={logic.disableApplynow}
                        onClick={logic.handleApplyNow}
                    >
                        Apply
                    </Button>
                </div>
            </Modal.Body>
            <span>&nbsp;</span>
        </Modal>
    );
};

export default DownPayment;