import React from 'react';
const RateChart = React.memo(( {downPayment,RBPObject,eligibleSegments,formatter,totalAmount} ) => {
    return (
                <div className="col-md-6 d-flex flex-column">
                    <span className='rate-chart'>Rate Chart</span>
                {eligibleSegments.slice().reverse().map((item, index) => {
                    
                        return ( typeof item.addOnRate !== undefined ? (
                        <span
                            style={{
                            backgroundColor: "#FFF",
                            padding: "15px",
                            border:
                                // If the first condition is true
                                item.isSelected
                                ? "3px solid #785AFF" // If the first condition is false and this condition is true
                                : "",
                                
                            }}
                            className="payment-tile d-flex flex-row justify-content-between"
                        >
                            <span className="d-flex flex-column justify-content-between">
                            <span className={item.isSelected ? "addon-rate selected-item" : 'addon-rate'}>
                                {item.addOnRate.toFixed(2)}%
                            </span>
                            <span className='addon-monthly'>
                                Add-on rate monthly
                            </span>
                            </span>
                            <span
                            className="d-flex flex-column justify-content-between"
                            style={{ textAlign: "right" }}
                            >
                            <span className={item.isSelected ? "addon-rate selected-item" : 'addon-rate'}>
                            {formatter.format(
                            Math.ceil(totalAmount * (item.dpPercent / 100))
                                )}
                            </span>
                            <span className='addon-monthly'>
                                Min. {item.dpPercent}% Downpayment
                            </span>
                            </span>
                        </span> ) : ""
                        );
                    }
                    )}
                </div>
    );
});
  
export default RateChart;