import React from "react";
import QRCode from "react-qr-code";
export const QRPage = ({ loanId }) => {
    return (
        <div className="col-md-4  h-100 mb-3">
            <div className="QR_product_info qr_block">
                <div>
                    <h5>{sessionStorage.getItem("merchantName")}</h5>
                </div>
                <div className="QR_code_space">
                    <div className="QR-Code-Genarated1">
                        <QRCode
                            size={150}
                            level="H"
                            value={JSON.stringify(loanId)}
                        />
                    </div>
                </div>
                <div className="QR-Desc">
                    <div className="Scan-QR text-primary">Scan me now!</div>
                    <div className="sub-Scan-QR text-primary">
                        <p>May 30 minutes ka para ipa-scan ako kay client.</p>
                        <p>Don’t keep me waiting, luv!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default QRPage;