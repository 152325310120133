import React, { Fragment,useState,useEffect } from 'react';
import { useLocation } from "react-router-dom";
import LoanTenure from './LoanTenure'
import Calculations from '../Calculations';
import DownPayment from './Modal/DownPayment';

export const CompareSlot = (props) => { 
    const {state} = useLocation();
    state.allItems = props.allItems;
    state.RBPObject = props.RBPObject;
    state.totalAmount = props.totalAmount;
    state.merchantStore = props.merchantStore;
    const [disableProceed,setdisableProceed] = useState(true);
    const [tenureSelect,setTenureSelect] = useState('');
    const logic = Calculations(state);
    useEffect(() => {
        // logic.setDownPayment(props.downPayment);
        document.querySelectorAll('.category-name')?.forEach(function(element,index){
            let e = {};
            e.target = element;
            e.compareCalc = true;
            element.value != '' && logic.handleInputChange(e, index);
        })
        logic.slotSelect(props.slot);
        logic.setTmpUpdatedLoanAmt(props.downPayment);
        logic.handleApplyNow({},props.downPayment);
        logic.setIsSilZero(props.isSilZero);
    }, [])

    useEffect(() => {
        setTenureSelect("");
    },[logic.downPayment]);
    useEffect(() => {
        setdisableProceed((tenureSelect == ""));
    },[tenureSelect]);

    const DownPayModalObject =  {
        showDownPay: logic.showDownPay,
        handleDownPayClose: logic.handleDownPayClose,
        formatter: logic.formatter,
        downPayment: logic.downPayment,
        handledownpayChange: logic.handledownpayChange,
        handleDecimalNumbers: logic.handleDecimalNumbers,
        handlePasteDecimalNumbers: logic.handlePasteDecimalNumbers,
        RBPObject: logic.RBPObject,
        disableApplynow: logic.disableApplynow,
        handleApplyNow: logic.handleApplyNow,
      };
    
    function popupSelected(e, vasFlagValue) {
        if(!props.showCompareModal) {
            props.getEMIDetails(e, vasFlagValue);
            return true;
        }
        // e.target.closest('.tenure-section').querySelector('.selected-tenure')?.classList.remove('selected-tenure');
        // e.currentTarget.classList.add('selected-tenure');
        setTenureSelect(e.currentTarget.getAttribute('term'));
    }

    const slotSelect = (e) => {
        props.handleApplyNow({},logic.downPayment).then(result => {
            let selectedElement = e.target.closest('.slot-compare').querySelector('[data-select="selected-tenure"]');
            let dataId = selectedElement?.getAttribute('loanproductid');
            if(dataId)
            {
                e.currentTarget = selectedElement;
                props.getEMIDetails(e, 0);
            }
            props.setShowCompareModal(false);
        });
    }

    const params = {
        loanableAmount : logic.loanableAmount,
        selected : logic.selected,
        getEMIDetails : logic.getEMIDetails,
        vasTenureList : logic.vasTenureList,
        VASFlag : logic.VASFlag,
        productTenur : logic.productTenur,
        formatter : logic.formatter,
        emiCalculator : logic.emiCalculator,
        vasEmiCalculator : logic.vasEmiCalculator,
        loadVAS:false,
        comparePopup:true,
        popupSelected:popupSelected,
        slot: props.slot,
        hideTenure:logic.hideTenure,
        tenureSelect:tenureSelect
    };

    return (
        <Fragment>
        <div class="slot-compare">
            <div class="price-slot">
                <h4>{logic.formatter.format(logic.totalAmount)}</h4>
                <p class="tg-name">Total price </p>
            </div>
            <div class="price-slot down-payment">
                <h4>{logic.formatter.format(logic.downPayment)}</h4>
                <p class="tg-name">Downpayment</p>
                <button className="btn-purple btn"onClick={(e) => logic.handleShow(e, props.slot)}>Change amount</button>
            </div>
            <div class="price-slot">
                <h4>{logic.formatter.format(logic.loanableAmount)}</h4>
                <p class="tg-name">Loanable amount</p>
            </div>
            <div class="price-slot" >
                <h4>{logic.currentSegmentObj ? logic.currentSegmentObj?.addOnRate?.toFixed(2) : ""}%</h4>
                <p class="tg-name">Add-on rate</p>
            </div>
            <h3>Installment terms</h3>
            {props.isSilZero ? <p className="sil-zero-proccessfee">Inclusive of processing fee**</p> : ""}
            <p>Tanungin si client sa kanyang preferred loan term</p>
            <LoanTenure {...params} />
            <button disabled={disableProceed} className="btn-purple btn" onClick={slotSelect}>Proceed</button>
        </div>
        <DownPayment {...DownPayModalObject} />
        </Fragment>

    );
};
  
export default CompareSlot;