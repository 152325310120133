import axios from 'axios';
export function validation(form) {
    let errors = {}
    if (isEmpty(form.username)) {
        errors.username = "Please Enter Username";
    }
    if (isEmpty(form.password)) {
        errors.password = "Please Enter Password";
    }

    return errors;

}
const isEmpty = (value) => (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
)

export const getAccessToken = () => {
    //apigee access token api call
    let access_token = ''
    const username = process.env.REACT_APP_APIGEE_OAUTH_EMAIL;
    const password = process.env.REACT_APP_APIGEE_OAUTH_PASSWORD;


    return axios.post(process.env.REACT_APP_APIGEE_API_DM + process.env.REACT_APP_APIGEE_OAUTH_API, {}, {
        headers: {
            "Content-Type": 'application/x-www-form-urlencoded',
            "Access-Control-Allow-Origin": "*"
        },
        auth: {
            "username": username,
            "password": password
        }
    })

}