import React, { Fragment } from 'react';
export const LoanTenure = (props) => {
    return (
        <section className="tenure-section">
            <div className="row nonVas">
                <div className={ `${props.comparePopup ? "d-flex flex-column" : "d-flex"} `  }>
                    {props.productTenur.map((items, index) => {
                        return (
                        (props.hideTenure.includes(items.tenur) == false) && <div className={ `${props.comparePopup ? "col-md-12 price-slot tenure-slot" : "col-md-3"} `} data-select={props.tenureSelect == items.tenur ? "selected-tenure" : ""} id={"tenure_" +(props.slot ? props.slot : '') + items.tenur} data-id={"tenure_"+ items.tenur} key={index + 1}
                            loanproductid={items.loanProductId} term={items.tenur} tenure={items.description}
                            interestrate={items.interestRate} loanAmount={props.loanableAmount} amount={props.emiCalculator(
                            props.loanableAmount, items.tenureDetail ? items.tenureDetail : items )} processFee={items.processFee} onClick={(e)=> props.comparePopup ? props.popupSelected(e, 0) : props.getEMIDetails(e, 0)}
                            >
                            <div className="d-flex flex-column payment-tile" style={{ border: props.loanableAmount> 0 &&
                                    items.loanProductId == props.selected
                                    ? "3px solid var(--primary-color)"
                                    : "",
                                    }}>
                                <div className={ `${props.comparePopup ? "" : "p-4 loan_EMI_Details"} `}>
                                    <div className="tenure">
                                        <span>{items.description}</span>
                                    </div>
                                    <div className="payHingaTag">
                                        <a>With PayHinga</a>
                                    </div>
                                    <div className="emi-amount">
                                        <span>
                                            {props.formatter.format(
                                            props.emiCalculator(
                                            props.loanableAmount,
                                            items.tenureDetail ? items.tenureDetail : items,
                                            ).toFixed(2)
                                            )}
                                        </span>
                                    </div>
                                    <div className="description">
                                        <p className='tg-name'>PER MONTH</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                    );
                    })}
                </div>
            </div>
            {props.loadVAS ? (
                <Fragment>
                    <div className="row">
                        <div className="d-flex dots">
                            {props.productTenur.length > 0 &&
                            props.vasTenureList.map((items, index) => {
                            return (

                                (props.hideTenure.includes(items.tenure) === false) && <div className="col-md-3" id={"tenure_vas_support_"+(props.slot ? props.slot : '') + items.tenure} style={{ display: props.VASFlag ? "block"
                                : "none",height: 100 / props.productTenur.length + "%" }}>
                                <a className="centered-link">
                                    <strong>|</strong>
                                </a>
                            </div>
                            );
                            })}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="d-flex ">
                            {props.productTenur.length > 0 &&
                            props.vasTenureList.map((items, index) => {
                            return (
                                (props.hideTenure.includes(items.tenure) === false) && <div className="col-md-3" id={"tenure_vas_"+(props.slot ? props.slot : '') + items.tenure} key={index + 1} loanproductid={items.feeTenureId}
                                term={items.tenure} tenure={items.tenure} interestrate={items.feeValue} loanAmount={props.loanableAmount}
                                feeValueType={items.feeValueType} amount={props.vasEmiCalculator( props.loanableAmount, items.tenureDetail ? items.tenureDetail : items )} onClick={(e)=>
                                props.getEMIDetails(e, 1)}
                                >
                                <div className="d-flex flex-column payment-tile" style={{ border: props.loanableAmount> 0 &&
                                        items.feeTenureId == props.selected
                                        ? "3px solid var(--primary-color)"
                                        : "",fontWeight: "600"
                                        }}>
                                    <div className=" p-4 loan_EMI_Details" >
                                        <div className="tenure">
                                            <span>{items.tenure} months</span>
                                        </div>
                                        <div className="payHingaTag">
                                            <a>With PayHinga</a>
                                        </div>
                                        <div className="emi-amount">
                                            <span>
                                                {props.formatter.format(
                                                props.vasEmiCalculator(
                                                props.loanableAmount,
                                                items.tenureDetail ? items.tenureDetail : items
                                                ).toFixed(2)
                                                )}
                                            </span>
                                        </div>
                                        <div className="description">
                                        <p class="tg-name">PER MONTH</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            );
                            })}
                        </div>
                    </div>
                </Fragment>
             ) : ''}
        </section>
    );
};
  
export default LoanTenure;