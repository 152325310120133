function Validate(validateForm) {
    let errors = {}
    var regexValidatePassword = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/;
if(!validateForm.newPassword){
    errors.newPassword= "Please Enter New Password";
}
if(!validateForm.confirmPassword){
    errors.confirmPassword= "Please Enter Confirm Password";
}
else if(validateForm.newPassword!=validateForm.confirmPassword){
    errors.confirmPassword= "New password and confirm password must be same";
}
else if(!regexValidatePassword.test(validateForm.confirmPassword)){
    errors.confirmPassword= "Please Follow the given pattern ";
}   

    return errors;

}
export default Validate;