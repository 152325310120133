const token="userToken";

export const login=(msg)=>{
   // alert(msg)
 localStorage.setItem(token,msg);   
}
export const loginOut=()=>{
    localStorage.removeItem(token);  
}

export const isLogin=()=>{
    if(localStorage.getItem(token)){
        return true;
    }
    return false;
}