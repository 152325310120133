import axios from 'axios';

const typeId = process.env.REACT_APP_PAYHINGA_TYPE_ID;
const apigeeDomain = process.env.REACT_APP_APIGEE_API_DM;
const vasFeeUrl = process.env.REACT_APP_APIGEE_VAS_FEE_URL;

export const getVasRuleInfo = async (setVasFlag, vasDetails, state) => {
    const options = {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${state.token}`,
        },
        params: {
            'typeId': typeId,
            'channelId': 'promoter',
            'merchantId': state.selectedValue,
        }
    };

    try {
        const response = await axios.get(`${apigeeDomain}${vasFeeUrl}`, options);

        vasFlagStatusUpdate(state, setVasFlag, false);
        state.typeId = parseInt(typeId);

        const arrayList = response.data.data.tenures || [];

        if (arrayList.length === 0) {
            htmlUpdateCss();
            console.log("The VAS rule info was null or empty.");
        } else {
            vasFlagStatusUpdate(state, setVasFlag, true);

            const optionsByCriteriaType = {
                T: arrayList.reduce((acc, tenure) => {
                    acc.push(...tenure.options.filter(option => option.criteriaType === 'T'));
                    return acc;
                }, []),
                A: arrayList.reduce((acc, tenure) => {
                    acc.push(...tenure.options.filter(option => option.criteriaType === 'A'));
                    return acc;
                }, []),
            };

           // setVasTenureList(optionsByCriteriaType.T.sort((a, b) => b.tenure - a.tenure));
            state.vasTenureList = optionsByCriteriaType.T;
            localStorage.setItem('vasStateObj', JSON.stringify(state));
            return optionsByCriteriaType.T.sort((a, b) => b.tenure - a.tenure);
            // console.log('forEach operation is completed.' + JSON.stringify(localStorage.getItem('vasStateObj').vasTenureList));
        }
    } catch (err) {
        console.error("API Error: ", err);
        vasFlagStatusUpdate(state, setVasFlag, false);
        htmlUpdateCss();
        // throw new Error("An error occurred while making the VAS rule API call.");
    }
};

const htmlUpdateCss = () => {
    setTimeout(() => {}, 500);
    const termsInfoContainer = document.getElementById("termsInfoContainer");
    if (termsInfoContainer) {
        termsInfoContainer.style.gridTemplateColumns = "100%";
    } else {
        console.error("Element with ID 'termsInfoContainer' not found.");
    }
}

const vasFlagStatusUpdate = (state, setVasFlag, flag) => {
    setVasFlag(flag);
    state.VASFlag = flag;
    state.vasAPI = flag;
}
