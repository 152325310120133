import React from 'react';
import LoanTenure from './LoanTenure'

export const InstallmentTerms = (props) => { 
    const params = {
        loanableAmount : props.loanableAmount,
        selected : props.selected,
        getEMIDetails : props.getEMIDetails,
        vasTenureList : props.vasTenureList,
        VASFlag : props.VASFlag,
        productTenur : props.productTenur,
        formatter : props.formatter,
        emiCalculator : props.emiCalculator,
        vasEmiCalculator:props.vasEmiCalculator,
        loadVAS:true,
        hideTenure:props.hideTenure
    };
    let showTenure = false;
    if((props.RBPObject.status === 'N') && (props.loanableAmount >= props.minLoanAmount)) {
        showTenure = true;
    } else if((props.RBPObject.status === 'Y') && !((props.eligibleSegments.length === 0 || props.downPayment < props.addOnSelectedDp))) {
        showTenure = true;
    }
    return (
        <div className="payment-checks overflow-auto">
            <div className="title">Installment terms</div>
            {props.isSilZero ? <p className="sil-zero-proccessfee">Inclusive of processing fee**</p> : ""}
            <div className="sub-title" style={{ fontWeight: "400", color:"#373739" }}>
            Tanungin si client sa kanyang preferred loan term
            </div>
            <div className="sub-title mb-2">
            <span
                className="tonik-color"
                onClick={props.payhingaPopupShow}
                disabled={props.payhingaDisableButton}
                style={{ cursor: "pointer", fontWeight: "600" }}
            >
                <span>{props.payhingaLinkText}</span>
            </span>
            </div>
            {
            showTenure ? (
            <>
                <LoanTenure {...params} />
            </>
            ) : '' }
        </div>
    );
};
  
export default InstallmentTerms;